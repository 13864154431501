import JSBI from 'jsbi'

export interface TokenConfig {
  address: string | undefined;
  decimals: number | undefined;
  symbol: string | undefined;
  name: string | undefined;
}

export interface ConfigInterface {
  factoryAddress: string | undefined;
  initCodeHash: string | undefined;
  mainnetId: number | undefined;
  testnetId: number | undefined;
  mainnetToken: TokenConfig | undefined;
  testnetToken: TokenConfig | undefined;
}

class ConfigClass {
  public static FACTORY_ADDRESS: string | undefined
  public static INIT_CODE_HASH: string | undefined
  public static MAINNET_ID: number | undefined
  public static TESTNET_ID: number | undefined
  public static MAINNET_TOKEN: TokenConfig | undefined
  public static TESTNET_TOKEN: TokenConfig | undefined
  static configure = (config: ConfigInterface) => {
    ConfigClass.FACTORY_ADDRESS = config.factoryAddress
    ConfigClass.INIT_CODE_HASH = config.initCodeHash
    ConfigClass.MAINNET_ID = config.mainnetId
    ConfigClass.TESTNET_ID = config.testnetId
    ConfigClass.MAINNET_TOKEN = config.mainnetToken
    ConfigClass.TESTNET_TOKEN = config.testnetToken
  }
}

// exports for external consumption
export type BigintIsh = JSBI | bigint | string

export enum TradeType {
  EXACT_INPUT,
  EXACT_OUTPUT
}

export enum Rounding {
  ROUND_DOWN,
  ROUND_HALF_UP,
  ROUND_UP
}

export const Config = ConfigClass

export const MINIMUM_LIQUIDITY = JSBI.BigInt(1000)

// exports for internal consumption
export const ZERO = JSBI.BigInt(0)
export const ONE = JSBI.BigInt(1)
export const TWO = JSBI.BigInt(2)
export const THREE = JSBI.BigInt(3)
export const FIVE = JSBI.BigInt(5)
export const TEN = JSBI.BigInt(10)
export const _100 = JSBI.BigInt(100)
export const _998 = JSBI.BigInt(998)
export const _1000 = JSBI.BigInt(1000)

export enum SolidityType {
  uint8 = 'uint8',
  uint256 = 'uint256'
}

export const SOLIDITY_TYPE_MAXIMA = {
  [SolidityType.uint8]: JSBI.BigInt('0xff'),
  [SolidityType.uint256]: JSBI.BigInt('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')
}
